export enum PreviousSubmissionStatus {
  AllSubmitted = 'AllSubmitted',
  NoneSubmitted = 'NoneSubmitted',
  SomeSubmitted = 'SomeSubmitted',
  NoFormsForPatient = 'NoFormsForPatient',
}

export type LoadFormsSubmittedResponse = {
  previousSubmissionStatus: PreviousSubmissionStatus;
};

export type LoadFormsCompleteResponse = {
  allFormsSubmitted: boolean;
};
