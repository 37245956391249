import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { RealtimeFormsActions } from './eforms-signalr.state';
import { extractTokenFromLauncherUrl } from './eforms-url.parser';
import { EformsApiActions, selectEformsPayload } from './eforms.state';
import { EformsHttpService } from './services/eforms-http-client.service';

/** Load eForms URL from Careflow. */
export const getEformsApiUrl$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), eformsHttpService = inject(EformsHttpService)) =>
    actions$.pipe(
      ofType(EformsApiActions.loadEformsUrlAttempted),
      withLatestFrom(store.select(selectEformsPayload)),
      switchMap(([, payload]) =>
        eformsHttpService.openEformsApi(payload).pipe(
          mergeMap(({ eformsUrl }) => [
            EformsApiActions.loadEformsUrlSuccess({ eformsUrl }),
            RealtimeFormsActions.loadExtractedCredentials({ eformsToken: extractTokenFromLauncherUrl(eformsUrl) }),
          ]),
          catchError((error: HttpErrorResponse) => of(EformsApiActions.loadEformsUrlFailed({ error: error.message }))),
        ),
      ),
    ),
  { functional: true },
);
