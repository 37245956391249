import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { selectAppointmentIds } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { selectAccountName } from '@abbadox-monorepo/kiosk-auth-data-access';
import { EformsUrlResponse } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { selectPatientMRN } from '@abbadox-monorepo/kiosk-patient-data-access';

export enum EformsPollingStatus {
  NOT_STARTED = 'NOT_STARTED',
  POLLING = 'POLLING',
  FINISHED = 'FINISHED',
}

export const EformsApiActions = createActionGroup({
  source: 'Eforms Api',
  events: {
    loadEformsUrlAttempted: emptyProps(),
    loadEformsUrlSuccess: props<EformsUrlResponse>(),
    loadEformsUrlFailed: props<{ error: string }>(),
  },
});

export const EformsPageActions = createActionGroup({
  source: 'Eforms Page',
  events: {
    initEformsState: emptyProps(),
    navigateToEforms: emptyProps(),
  },
});

export interface EformsState {
  eformsUrl: string;
  error: string;
  loaded: boolean;
}

export const initialEformsState: EformsState = {
  eformsUrl: '',
  error: '',
  loaded: false,
};

export const eformsFeature = createFeature({
  name: 'eforms',
  reducer: createReducer(
    initialEformsState,
    on(EformsApiActions.loadEformsUrlSuccess, (state, { eformsUrl }) => ({
      ...state,
      eformsUrl,
      loaded: true,
    })),
    on(EformsPageActions.initEformsState, () => initialEformsState),
  ),
});

export const {
  name: EFORMS_STATE_FEATURE_KEY,
  reducer: eformsReducer,
  selectLoaded: selectEformsUrlLoaded,
  selectEformsUrl,
} = eformsFeature;

export const selectEformsPayload = createSelector(
  selectAppointmentIds,
  selectPatientMRN,
  selectAccountName,
  (appointmentIds, patientMRN, accountName) => ({
    appointmentIds,
    patientMRN,
    accountName,
  }),
);
