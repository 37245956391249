/**
 * Extracts the token from a generated forms launcher url
 *
 * @param eformsUrl  - generated url to parse
 * @returns - token from the generated url
 */
export const extractTokenFromLauncherUrl = (eformsUrl: string) => {
  const parts = eformsUrl.split('/');
  return parts[parts.length - 5];
};
