import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { PreviousSubmissionStatus } from './models';

export const RealtimeFormsActions = createActionGroup({
  source: 'Realtime Forms',
  events: {
    loadExtractedCredentials: props<{ eformsToken: string }>(),
    loadFormsSubmittedStatus: props<{ formsSubmittedStatus: PreviousSubmissionStatus }>(),
    loadFormsCompletedStatus: props<{ formsCompleted: boolean }>(),
    connectToRealtimeFormsChannelSuccess: emptyProps(),
    connectToRealtimeFormsChannelFailed: props<{ error: string }>(),
    connectToRealtimeFormsChannelStopped: emptyProps(),
    initRealtimeFormsState: emptyProps(),
  },
});

export interface RealtimeFormsState {
  eformsToken: string;
  formsSubmittedStatus: PreviousSubmissionStatus;
  formsCompleted: boolean;
  error: string;
}

export const initialRealtimeFormsState: RealtimeFormsState = {
  eformsToken: '',
  formsSubmittedStatus: PreviousSubmissionStatus.NoneSubmitted,
  formsCompleted: false,
  error: '',
};

export const realtimeFormsFeature = createFeature({
  name: 'realtimeForms',
  reducer: createReducer(
    initialRealtimeFormsState,
    on(RealtimeFormsActions.loadExtractedCredentials, (state, { eformsToken }) => ({
      ...state,
      eformsToken,
    })),
    on(RealtimeFormsActions.loadFormsSubmittedStatus, (state, { formsSubmittedStatus }) => ({
      ...state,
      formsSubmittedStatus,
    })),
    on(RealtimeFormsActions.loadFormsCompletedStatus, (state, { formsCompleted }) => ({
      ...state,
      formsCompleted,
    })),
    on(RealtimeFormsActions.connectToRealtimeFormsChannelFailed, (state, { error }) => ({
      ...state,
      error,
    })),
    on(RealtimeFormsActions.initRealtimeFormsState, () => initialRealtimeFormsState),
  ),
});

export const {
  name: REALTIME_FORMS_FEATURE_KEY,
  reducer: realtimeFormsReducer,
  selectEformsToken,
  selectFormsSubmittedStatus,
  selectFormsCompleted,
} = realtimeFormsFeature;

export const selectEformsCompletedStatus = createSelector(
  selectFormsSubmittedStatus,
  selectFormsCompleted,
  (submittedStatus, formsCompleted) =>
    [PreviousSubmissionStatus.AllSubmitted, PreviousSubmissionStatus.NoFormsForPatient].includes(submittedStatus) ||
    formsCompleted,
);
