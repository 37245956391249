import { InjectionToken } from '@angular/core';

import { HubKeyDefinition } from 'ngrx-signalr-core';

type HubDefinition = HubKeyDefinition & {
  automaticReconnect: boolean;
};

/**
 * Specify all the SignalR tokens used accross various apps here.
 *
 * Configs from tokens use `ngrx-signalr-core` for seamless integration with existing state management libraries.
 */
export const SIGNLAR_FORMS_HUB_CONFIG = new InjectionToken<HubDefinition>('SIGNLAR_FORMS_HUB_CONFIG');
