import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { RealtimeFormsActions } from '../eforms-signalr.state';
import { EformsPageActions } from '../eforms.state';

/** Resets the state of the workflow anytime the home page is navigated to. */
export const eformsResetResolver: ResolveFn<boolean> = () => {
  const store = inject(Store);

  store.dispatch(EformsPageActions.initEformsState());
  store.dispatch(RealtimeFormsActions.initRealtimeFormsState());
  store.dispatch(RealtimeFormsActions.connectToRealtimeFormsChannelStopped());

  return of(true);
};
