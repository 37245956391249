import { createAction, props, union, createReducer, on, createSelector } from '@ngrx/store';
import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import * as i1 from '@ngrx/effects';
import { createEffect, ofType } from '@ngrx/effects';
import { Subject, throwError, Observable, from, fromEvent, merge, of, EMPTY, timer } from 'rxjs';
import { share, map, startWith, filter, mergeMap, switchMap, exhaustMap, catchError, groupBy, takeUntil } from 'rxjs/operators';
import { HubConnectionState, HubConnectionBuilder, Subject as Subject$1 } from '@microsoft/signalr';

/**
 * Action to dispatch in order to create a new SignalR hub.
 */
const createSignalRHub = createAction("@ngrx/signalr/createHub", props());
/**
 * Action dispatched when a hub is at `unstarted` state.
 */
const signalrHubUnstarted = createAction("@ngrx/signalr/hubUnstarted", props());
/**
 * Action to dispatch in order to start a SignalR hub.
 */
const startSignalRHub = createAction("@ngrx/signalr/startHub", props());
/**
 * Action to dispatch in order to stop a SignalR hub.
 */
const stopSignalRHub = createAction("@ngrx/signalr/stopHub", props());
/**
 * @deprecated Use `automaticReconnect` option when creating the SignalR hub.
 * Action to dispatch in order to reconnect to a SignalR hub.
 * It can be automatically dispatched using `createReconnectEffect` effect.
 */
const reconnectSignalRHub = createAction("@ngrx/signalr/reconnectHub", props());
/**
 * Action dispatched when a SignalR failed to start.
 */
const signalrHubFailedToStart = createAction("@ngrx/signalr/hubFailedToStart", props());
/**
 * Action dispatched when a hub is at `connected` state.
 */
const signalrConnected = createAction("@ngrx/signalr/connected", props());
/**
 * Action dispatched when a hub is at `reconnecting` state.
 */
const signalrReconnecting = createAction("@ngrx/signalr/reconnecting", props());
/**
 * Action dispatched when a hub is `reconnected`.
 */
const signalrReconnected = createAction("@ngrx/signalr/reconnected", props());
/**
 * Action dispatched when a hub is at `disconnected` state.
 */
const signalrDisconnected = createAction("@ngrx/signalr/disconnected", props());
/**
 * Action dispatched when an error occured with a SignalR hub.
 */
const signalrError = createAction("@ngrx/signalr/error", props());
/**
 * Action dispatched when a SignalR cannot be found, when doing any action.
 */
const hubNotFound = createAction("@ngrx/signalr/hubNotFound", props());
const signalRAction = union({
  createSignalRHub,
  signalrHubUnstarted,
  startSignalRHub,
  stopSignalRHub,
  reconnectSignalRHub,
  signalrHubFailedToStart,
  signalrConnected,
  signalrReconnecting,
  signalrReconnected,
  signalrDisconnected,
  signalrError,
  hubNotFound
});
let testingEnabled = false;
let hubCreationFunc;
/**
 * Enable testing of the ngrx-signalr-core package.
 * Only use this function for testing purpose ONLY.
 * @param func A function that will used to create a new SignalR hub.
 */
const enableTesting = func => {
  testingEnabled = true;
  hubCreationFunc = func;
};
const unstarted = "unstarted";
const connected = "connected";
const disconnected = "disconnected";
const reconnecting = "reconnecting";
const reconnected = "reconnected";
/**
 * List of given states a SignalR can be.
 */
const SignalRStates = {
  unstarted,
  connected,
  disconnected,
  reconnecting,
  reconnected
};
/**
 * Convert a hub connection state to the internal state value.
 * @param state The state of the hub connection.
 */
const toSignalRState = state => {
  switch (state) {
    case HubConnectionState.Connected:
      return connected;
    case HubConnectionState.Disconnected:
      return disconnected;
    case HubConnectionState.Reconnecting:
      return reconnecting;
  }
};
const createConnection = (url, options, automaticReconnect, withHubProtocol) => {
  const builder = new HubConnectionBuilder();
  if (options) {
    builder.withUrl(url, options);
  } else {
    builder.withUrl(url);
  }
  if (automaticReconnect === true) {
    builder.withAutomaticReconnect();
  }
  if (automaticReconnect instanceof Array) {
    builder.withAutomaticReconnect(automaticReconnect);
  }
  if (typeof automaticReconnect === "object" && "nextRetryDelayInMilliseconds" in automaticReconnect) {
    builder.withAutomaticReconnect(automaticReconnect);
  }
  if (withHubProtocol) {
    builder.withHubProtocol(withHubProtocol);
  }
  return builder.build();
};
class SignalRHub {
  get connectionId() {
    return this._connection?.connectionId ?? undefined;
  }
  constructor(hubName, url, options, automaticReconnect, withHubProtocol) {
    this.hubName = hubName;
    this.url = url;
    this.options = options;
    this.automaticReconnect = automaticReconnect;
    this.withHubProtocol = withHubProtocol;
    this._startSubject = new Subject();
    this._stopSubject = new Subject();
    this._stateSubject = new Subject();
    this._errorSubject = new Subject();
    this._subjects = {};
    this.start$ = this._startSubject.asObservable();
    this.stop$ = this._stopSubject.asObservable();
    this.state$ = this._stateSubject.asObservable();
    this.error$ = this._errorSubject.asObservable();
  }
  ensureConnectionOpened() {
    if (!this._connection) {
      this._connection = createConnection(this.url, this.options, this.automaticReconnect, this.withHubProtocol);
      this._connection.onclose(error => {
        if (error) {
          this._errorSubject.next(error);
        }
        this._stateSubject.next(disconnected);
      });
      this._connection.onreconnecting(() => {
        this._stateSubject.next(reconnecting);
      });
      this._connection.onreconnected(() => {
        this._stateSubject.next(reconnected);
      });
    }
    return this._connection;
  }
  start() {
    const connection = this.ensureConnectionOpened();
    connection.start().then(_ => {
      this._startSubject.next();
      this._stateSubject.next(connected);
    }).catch(error => this._errorSubject.next(error));
    return this._startSubject.asObservable();
  }
  stop() {
    if (!this._connection) {
      return throwError("The connection has not been started yet. Please start the connection by invoking the start method before attempting to stop listening from the server.");
    }
    this._connection.stop().then(_ => {
      this._stopSubject.next();
    }).catch(error => this._errorSubject.next(error));
    return this._stopSubject.asObservable();
  }
  on(eventName) {
    return new Observable(observer => {
      const connection = this.ensureConnectionOpened();
      const callback = data => observer.next(data);
      connection.on(eventName, callback);
      const errorSubscription = this._errorSubject.subscribe(() => {
        observer.error(new Error(`The connection has been closed.`));
      });
      const stopSubscription = this._stopSubject.subscribe(() => {
        observer.complete();
      });
      return () => {
        errorSubscription.unsubscribe();
        stopSubscription.unsubscribe();
        connection.off(eventName, callback);
      };
    }).pipe(share());
  }
  stream(methodName, ...args) {
    return new Observable(observer => {
      const connection = this.ensureConnectionOpened();
      const stream = connection.stream(methodName, ...args);
      const subscription = stream.subscribe(observer);
      return () => subscription.dispose();
    }).pipe(share());
  }
  send(methodName, ...args) {
    if (!this._connection) {
      return throwError("The connection has not been started yet. Please start the connection by invoking the start method before attempting to send a message to the server.");
    }
    return from(this._connection.invoke(methodName, ...args));
  }
  sendStream(methodName, observable) {
    const connection = this.ensureConnectionOpened();
    const internalSubject = new Subject$1();
    observable.subscribe(internalSubject);
    connection.send(methodName, internalSubject);
  }
  hasSubscriptions() {
    for (let key in this._subjects) {
      if (this._subjects.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }
}
const hubs = [];
function findHub(x, url) {
  if (typeof x === "string") {
    return hubs.filter(h => h.hubName === x && h.url === url)[0];
  }
  return hubs.filter(h => h.hubName === x.hubName && h.url === x.url)[0];
}
/**
 * Create a new SignalR hub instance.
 * @param hubName Name of the hub.
 * @param url Url of the hub.
 * @param options Configuration of the hub.
 * @param automaticReconnect Options to configure the {@link @microsoft/signalr.HubConnection} to automatically attempt to reconnect if the connection is lost.
 * @param withHubProtocol Options to configure the {@link @microsoft/signalr.HubConnection} to use specified {@link @microsoft/signalr.IHubProtocol}.
 */
const createHub = (hubName, url, options, automaticReconnect, withHubProtocol) => {
  if (testingEnabled) {
    const hub = hubCreationFunc(hubName, url, options, automaticReconnect, withHubProtocol);
    if (hub) {
      hubs.push(hub);
      return hub;
    }
    return undefined;
  }
  const hub = new SignalRHub(hubName, url, options, automaticReconnect, withHubProtocol);
  hubs.push(hub);
  return hub;
};

/**
 * Returns an Observable with the current status of network connection, whether online or offline.
 */
const isOnline$ = () => {
  const offline$ = fromEvent(window, "offline").pipe(map(() => false));
  const online$ = fromEvent(window, "online").pipe(map(() => true));
  return merge(offline$, online$).pipe(startWith(navigator.onLine));
};
function ofHub(x, url) {
  if (typeof x === "string") {
    return filter(action => action.hubName === x && action.url === url);
  } else {
    return filter(action => action.hubName === x.hubName && action.url === x.url);
  }
}
/**
 * Map the hub definition (hub name, url) to an existing SignalR hub instance.
 */
const mapToHub = () => map(findHub);
const hubAndActionOrNotFound = func => action => {
  const search = action;
  const hub = findHub(search);
  if (!hub) {
    return of(hubNotFound(search));
  }
  return func({
    action,
    hub
  });
};
/**
 * Map a hub action to a new object that contains
 * both the original action and the SignalR hub instance,
 * using `mergeMap` rxjs operator.
 * @param func A function that returns an Observable according to the given action and SignalR hub instance.
 */
const mergeMapHubToAction = func => mergeMap(hubAndActionOrNotFound(func));
/**
 * Map a hub action to a new object that contains
 * both the original action and the SignalR hub instance,
 * using `switchMap` rxjs operator.
 * @param func A function that returns an Observable according to the given action and SignalR hub instance.
 */
const switchMapHubToAction = func => switchMap(hubAndActionOrNotFound(func));
/**
 * Map a hub action to a new object that contains
 * both the original action and the SignalR hub instance,
 * using `exhaustMap` rxjs operator.
 * @param func A function that returns an Observable according to the given action and SignalR hub instance.
 */
const exhaustMapHubToAction = func => exhaustMap(hubAndActionOrNotFound(func));

/**
 * Collection of effects to execute realtime events (hub creation, starting, stopping, etc..).
 */
class SignalREffects {
  constructor(actions$) {
    this.actions$ = actions$;
    /**
     * Automatically create a new SignalR hub (then set hub state to `unstarted` by default).
     */
    this.createHub$ = createEffect(() => this.actions$.pipe(ofType(createSignalRHub), map(action => {
      const hub = createHub(action.hubName, action.url, action.options, action.automaticReconnect, action.withHubProtocol);
      if (!hub) {
        return signalrError({
          hubName: action.hubName,
          url: action.url,
          error: "Unable to create SignalR hub..."
        });
      }
      return signalrHubUnstarted({
        hubName: hub.hubName,
        url: hub.url
      });
    })));
    /**
     * Listen to every change on the SignalR hub.
     * Listen to start result (success/fail).
     * Listen to change connection state (connected, disconnected).
     * Listen to hub error.
     */
    this.beforeStartHub$ = createEffect(() => this.actions$.pipe(ofType(signalrHubUnstarted), mergeMapHubToAction(({
      hub,
      action
    }) => {
      const start$ = hub.start$.pipe(mergeMap(_ => EMPTY), catchError(error => of(signalrHubFailedToStart({
        hubName: action.hubName,
        url: action.url,
        error
      }))));
      const state$ = hub.state$.pipe(mergeMap(state => {
        if (state === connected) {
          return of(signalrConnected({
            hubName: action.hubName,
            url: action.url,
            connectionId: hub.connectionId
          }));
        }
        if (state === disconnected) {
          return of(signalrDisconnected({
            hubName: action.hubName,
            url: action.url
          }));
        }
        if (state === reconnecting) {
          return of(signalrReconnecting({
            hubName: action.hubName,
            url: action.url
          }));
        }
        if (state === reconnected) {
          return of(signalrReconnected({
            hubName: action.hubName,
            url: action.url
          }));
        }
        return EMPTY;
      }));
      const error$ = hub.error$.pipe(map(error => signalrError({
        hubName: action.hubName,
        url: action.url,
        error
      })));
      return merge(start$, state$, error$);
    })));
    /**
     * Automatically start hub based on actions dispatched.
     */
    this.startHub$ = createEffect(() => this.actions$.pipe(ofType(startSignalRHub, reconnectSignalRHub), mergeMapHubToAction(({
      hub
    }) => {
      return hub.start().pipe(mergeMap(_ => EMPTY), catchError(error => of(signalrError({
        hubName: hub.hubName,
        url: hub.url,
        error
      }))));
    })));
    /**
     * Automatically stop hub based on actions dispatched.
     */
    this.stopHub$ = createEffect(() => this.actions$.pipe(ofType(stopSignalRHub), mergeMapHubToAction(({
      hub
    }) => {
      return hub.stop().pipe(mergeMap(_ => EMPTY), catchError(error => of(signalrError({
        hubName: hub.hubName,
        url: hub.url,
        error
      }))));
    })));
  }
  static {
    this.ɵfac = function SignalREffects_Factory(t) {
      return new (t || SignalREffects)(i0.ɵɵinject(i1.Actions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SignalREffects,
      factory: SignalREffects.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignalREffects, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: i1.Actions
  }], null);
})();
const TEN_SECONDS = 10 * 1000;
/**
 * @deprecated Use `automaticReconnect` option when creating the SignalR hub.
 * Create an @ngrx effect to handle SignalR reconnection automatically.
 * @param actions$ Observable of all actions dispatched in the current app.
 * @param options Options to configure the effect.
 */
const createReconnectEffect = (actions$, options) => {
  const defaultReconnect$ = timer(0, TEN_SECONDS).pipe(switchMap(isOnline$), filter(isOnline => isOnline));
  return createEffect(() => actions$.pipe(ofType(signalrDisconnected), filter(action => !options || !options.hubName || action.hubName === options.hubName), groupBy(action => action.hubName), mergeMap(group => group.pipe(exhaustMapHubToAction(({
    action
  }) => {
    const reconnect$ = options && options.reconnectionPolicy ? options.reconnectionPolicy(action) : defaultReconnect$;
    return reconnect$.pipe(map(_ => reconnectSignalRHub(action)), takeUntil(actions$.pipe(ofType(signalrConnected), ofHub(action))));
  }), takeUntil(actions$.pipe(ofType(stopSignalRHub), filter(action => action.hubName === group.key)))))));
};
class SignalRTestingHub {
  get connectionId() {
    return undefined;
  }
  constructor(hubName, url, options) {
    this.hubName = hubName;
    this.url = url;
    this.options = options;
    this._startSubject = new Subject();
    this._stopSubject = new Subject();
    this._stateSubject = new Subject();
    this._errorSubject = new Subject();
    this._subjects = {};
    this.start$ = this._startSubject.asObservable();
    this.stop$ = this._stopSubject.asObservable();
    this.state$ = this._stateSubject.asObservable();
    this.error$ = this._errorSubject.asObservable();
  }
  start() {
    timer(100).subscribe(_ => {
      this._startSubject.next();
      this._stateSubject.next(connected);
    });
    return this._startSubject.asObservable();
  }
  stop() {
    timer(100).subscribe(_ => {
      this._stopSubject.next();
      this._stateSubject.next(disconnected);
    });
    return this._stopSubject.asObservable();
  }
  hasSubscriptions() {
    for (let key in this._subjects) {
      if (this._subjects.hasOwnProperty(key)) {
        return true;
      }
    }
    return false;
  }
}
const initialState = {
  hubStatuses: []
};
const reducer = createReducer(initialState, on(createSignalRHub, (state, action) => ({
  ...state,
  hubStatuses: state.hubStatuses.concat([{
    hubName: action.hubName,
    url: action.url,
    state: unstarted
  }])
})), on(signalrHubUnstarted, (state, action) => {
  return {
    ...state,
    hubStatuses: state.hubStatuses.map(hs => {
      if (hs.hubName === action.hubName && hs.url === action.url) {
        return {
          ...hs,
          state: unstarted
        };
      }
      return hs;
    })
  };
}), on(signalrConnected, (state, action) => {
  return {
    ...state,
    hubStatuses: state.hubStatuses.map(hs => {
      if (hs.hubName === action.hubName && hs.url === action.url) {
        return {
          ...hs,
          state: connected
        };
      }
      return hs;
    })
  };
}), on(signalrReconnecting, (state, action) => {
  return {
    ...state,
    hubStatuses: state.hubStatuses.map(hs => {
      if (hs.hubName === action.hubName && hs.url === action.url) {
        return {
          ...hs,
          state: reconnecting
        };
      }
      return hs;
    })
  };
}), on(signalrDisconnected, (state, action) => {
  return {
    ...state,
    hubStatuses: state.hubStatuses.map(hs => {
      if (hs.hubName === action.hubName && hs.url === action.url) {
        return {
          ...hs,
          state: disconnected
        };
      }
      return hs;
    })
  };
}));
/**
 * A reducer to use on the SignalR store state.
 * @param state A state that contains SignalR hub information.
 * @param action An action to dispatch.
 */
function signalrReducer(state, action) {
  return reducer(state, action);
}

/**
 * Default SignalR feature name, mainly used in the root reducer.
 */
const DEFAULT_SIGNALR_FEATURENAME = "signalr";

/**
 * Feature selector to the select the part of the state about SignalR.
 * @param state Root state.
 */
const selectSignalrState = state => state[DEFAULT_SIGNALR_FEATURENAME];
/**
 * Select all hub statuses.
 */
const selectHubsStatuses = createSelector(selectSignalrState, state => state.hubStatuses);
/**
 * Select a single hub status.
 */
const selectHubStatus = (hubName, url) => createSelector(selectSignalrState, state => state.hubStatuses.filter(hs => hs.hubName === hubName && hs.url === url)[0]);
/**
 * Select a value (true or false) when all hubs are connected.
 */
const selectAreAllHubsConnected = createSelector(selectHubsStatuses, hubStatuses => hubStatuses.every(hs => hs.state === connected));
/**
 * Select a value (true or false) when a single hub have a given status (unstarted, connected, disconnected).
 */
const selectHasHubState = (hubName, url, state) => createSelector(selectHubStatus(hubName, url), hubStatus => !!hubStatus && hubStatus.state === state);
class StoreSignalRService {
  findHub(x, url) {
    if (typeof x === "string") {
      return findHub(x, url || "");
    } else {
      return findHub(x.hubName, x.url);
    }
  }
  createHub(hubName, url, options) {
    return createHub(hubName, url, options);
  }
  static {
    this.ɵfac = function StoreSignalRService_Factory(t) {
      return new (t || StoreSignalRService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StoreSignalRService,
      factory: StoreSignalRService.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreSignalRService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngrx-signalr-core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { SignalREffects, SignalRHub, SignalRStates, SignalRTestingHub, StoreSignalRService, createHub, createReconnectEffect, createSignalRHub, enableTesting, exhaustMapHubToAction, findHub, hubNotFound, mapToHub, mergeMapHubToAction, ofHub, reconnectSignalRHub, selectAreAllHubsConnected, selectHasHubState, selectHubStatus, selectHubsStatuses, selectSignalrState, signalrConnected, signalrDisconnected, signalrError, signalrHubFailedToStart, signalrHubUnstarted, signalrReconnected, signalrReconnecting, signalrReducer, startSignalRHub, stopSignalRHub, switchMapHubToAction, testingEnabled };
